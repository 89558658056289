<template>
  <div class="edit-target-ad">
    <b-card>
      <div class="heading">
        <p>
          Edit Target Ads
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="editTargetAd">
        <b-form
          class="mt-2"
          style="
          width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Target Ads Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Target Ads Name"
                rules="required"
              >
                <b-skeleton
                  v-if="isTargetAds"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="blog-edit-title"
                  v-model="postData.name"
                  placeholder="Please enter Target Ads name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="heading">
              <p>
                Target Audience
              </p>
            </div>
            <b-form-group
              text-bolder
              label="Date and Time"
              label-for="blog-edit-date-time"
              class="mb-2 campaign-label"
            >
              <validation-provider
                id="blog-edit-date-time"
                v-slot="{ errors }"
                name="Date and Time"
                rules="required"
              >
                <b-skeleton
                  v-if="isTargetAds"
                  type="input"
                />
                <flat-pickr
                  v-else
                  v-model="postData.time"
                  class="form-control"
                  :config="{enableTime: true,disable:[{from:'0000-00-00',to:limit}],dateFormat: 'Y-m-d H:i'}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              text-bolder
              label="Location"
              label-for="blog-map"
              class="campaign-label"
            >
              <validation-provider
                id="blog-map"
                name="Location"
              >
                <Map
                  v-if="show"
                  ref="map-view-id"
                  :center-of-circle="postData.location"
                  :radius="postData.radius"
                  @circleCenterSetFunc="circleCenterSetFunc"
                  @circleRadiusSetFunc="circleRadiusSetFunc"
                  @devicesGetLatLong="devicesGetLatLong"
                />
                <span v-else>
                  <b-skeleton-img />
                </span>
                <div class="d-flex justify-content-center align-items-center mt-1">
                  <b-button
                    variant="primary"
                    :disabled="addLocationSpinner"
                    @click="getAndCountOfTheDevicesByArea()"
                  >
                    <div
                      v-if="addLocationSpinner"
                      class="spinner"
                    >
                      <b-spinner
                        small
                      />
                    </div>
                    Find Devices
                  </b-button>
                </div>
                <div />
                <br>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="maxTimeLimit"
        @addMediaToCampaign="addMediaToTargetAd"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        @removeMediaFromList="deleteMediaFromTargetAd"
      />
      <div class="d-flex justify-content-center mt-0">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewTargetAdsCampaign"
        >
          Review
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="editTargetAds"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Save
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelTargetAds"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, BSpinner, BSkeleton, BSkeletonImg,
} from 'bootstrap-vue'
// import _ from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import Map from '@/components/target-ads/Map.vue'
import TimeLimitCondition from '@/common/config'

Vue.prototype.moment = moment
export default {
  name: 'CreateTargetAd',
  components: {
    Map,
    flatPickr,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    AddedMediaToCampaign,
    BButton,
    BSkeleton,
    BannerAdsMedia,
    BSpinner,
    BSkeletonImg,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
  },
  data() {
    return {
      show: false,
      maxTimeLimit: 20,
      TimeLimitCondition,
      isTargetAds: false,
      spinner: false,
      clearPolygonSpinner: false,
      addLocationSpinner: false,
      postData: {
        name: null,
        targetAdId: JSON.parse(this.$route.params.id),
        time: null,
        location: [],
        radius: 0,
        target_ads_has_media: [],
      },
      devicesPaths: [],
      polygonCoordinates: [],
      addedFilesToCampaign: [],
      currentTimeSpan: '',
      limit: '',
      totalDevices: 0,
      // validation
      required,
    }
  },
  watch: {
    devicesPaths() {
      this.$refs['map-view-id'].devicesMarkerFunc(this.devicesPaths)
    },
    'postData.time': function () {
      this.postData.time = moment(this.postData.time).format('YYYY-MM-DD[T]h:mm')
    },
  },
  mounted() {
    this.disabledTimeFunc()
    this.getTargetAds(this.$route.params.id)
  },
  methods: {
    getTargetAds(id) {
      this.isTargetAds = true
      return this.$axios
        .get(`target-ads/get-target-ad/${id}`)
        .then(async ({ data }) => {
          // eslint-disable-next-line prefer-destructuring
          this.targetads = data.data
          this.postData.name = this.targetads.name
          this.postData.time = this.targetads.time
          this.postData.radius = this.targetads.radius * 1000
          this.postData.time = moment(this.postData.time).format('YYYY-MM-DD[T]h:mm')
          const array = (this.targetads.location.slice(6, -2)).split(',')
          await this.getCircleCenterPoints(array)
          await this.getAndCountOfTheDevicesByArea()
          this.addedFilesToCampaign = this.targetads.target_ads_has_media.map(res => res.media)
          this.updateIdsOfMediaInPayload()
          this.isTargetAds = false
        }).catch(response => (this.$swal(response.message)))
    },
    getCircleCenterPoints(array) {
      const emptyArray = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < array.length; i++) {
        const subArray = array[i].split(' ')
        emptyArray.push({ lat: JSON.parse(subArray[0]), lng: JSON.parse(subArray[1]) })
      }
      if (emptyArray.length > 0) {
        this.show = true
      }
      // eslint-disable-next-line prefer-destructuring
      this.postData.location = emptyArray[0]
    },
    updateIdsOfMediaInPayload() {
      this.postData.target_ads_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    disabledTimeFunc() {
      this.currentTimeSpan = moment(Date.now()).format('YYYY-MM-DD[T]h:mm a')
      this.limit = moment((Date.now() + (TimeLimitCondition.condition * 60 * 60 * 1000))).format('YYYY-MM-DD[T]h:mm a')
      // const limit = (currentTimeSpan + (48 * 60 * 60 * 1000)) | moment
    },
    async editTargetAds() {
      this.$refs.editTargetAd.validate().then(async success => {
        if (success) {
          this.spinner = true
          await this.editTargetAdsData()
          this.spinner = false
        }
      })
    },
    async editTargetAdsData() {
      try {
        await this.$axios
          .put('/target-ads/update-target-ad', this.postData)
        await this.$router.push('/target-ads')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
      }
    },
    async getAndCountOfTheDevicesByArea() {
      this.addLocationSpinner = true
      try {
        const requestBody = { location: this.postData.location, radius: this.postData.radius, time: this.postData.time }
        const res = await this.$axios
          .post('target-ads/get-available-devices', requestBody)
        const { data: resData } = res || {}
        const { data } = resData || {}
        const { deviceArray, count } = data || {}
        this.totalDevices = count
        await this.devicesGetLatLong(deviceArray)
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
      }
      this.addLocationSpinner = false
    },
    circleCenterSetFunc(center) {
      this.postData.location = center
    },
    circleRadiusSetFunc(radius) {
      this.postData.radius = Number(radius / 1000)
    },
    devicesGetLatLong(array) {
      this.devicesPaths = []
      const paths = []
      array.forEach(data => {
        paths.push({ lat: data.location.y, lng: data.location.x, name: data.name })
      })
      this.devicesPaths = JSON.parse(JSON.stringify(paths))
      return this.devicesPaths
    },
    // async clearPolygonFunc() {
    //   this.clearPolygonSpinner = true
    //   this.devicesPaths = []
    //   this.postData.location = []
    //   this.clearPolygonSpinner = false
    // },
    cancelTargetAds() {
      this.$router.push('/target-ads')
    },
    addMediaToTargetAd(media) {
      this.addedFilesToCampaign.push(media)
      this.updateIdsOfMediaInPayload()
    },
    deleteMediaFromTargetAd(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    async reviewTargetAdsCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.edit-target-ad{
  #map {
    height: 100%;
  }
  .map-div{
    height: 250px !important;
  }
  .heading{
    font-size: 20px;
    line-height: 44px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat",serif;
    text-align: center;
  }
  .form-control{
    height: 42px !important;
  }
  .campaign-label{
    font-size: 10px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat";
  }
}
</style>
